import invoiceControlTypes from "../type/invoiceControl";
import httpClient from "../../core/httpclient";
import ProjectConfig from "../../config/config";
import XMSErrorHandler from "../../core/xmsErrorHandler";
import { monthFirstDay, monthLastDay } from "../../core/dateStr";
import moment from "moment";

export const setDate = (StartDate, EndDate) => dispatch => {
    dispatch({
        type: invoiceControlTypes.SET_DATE_FILTER,
        StartDate: StartDate,
        EndDate: EndDate
    })
}
export const getInvoices = (StartDate, EndDate) => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/invoice/getinvoices`
    const GetInvoiceStatusRequest = {
        StartDateEpoch: new Date(StartDate).getTime() / 1000,
        EndDateEpoch: new Date(EndDate).getTime() / 1000
    }
    dispatch({
        type: invoiceControlTypes.GETTING_INVOICES
    })
    httpClient.post(endPoint, GetInvoiceStatusRequest)
        .then((response) => {
            const invoices = response.data.result
            dispatch({
                type: invoiceControlTypes.GET_INVOICES,
                Invoices: invoices
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: invoiceControlTypes.INVOICE_NOT_FOUND
            })
            dispatch({
                type: invoiceControlTypes.INVOICE_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
        })
}
export const deleteExcelInvoices = () => dispatch => {
    dispatch({
        type: invoiceControlTypes.DELETE_EXCEL_INVOICES
    })
}
export const getExcelInvoices = (StartDate, EndDate, exportFunction) => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/invoice/getinvoices`
    // const firstDay = monthFirstDay()
    // const lastDay = monthLastDay()
    const GetInvoiceStatusRequest = {
        StartDateEpoch: new Date(StartDate).getTime() / 1000,
        EndDateEpoch: new Date(EndDate).getTime() / 1000
    }
    dispatch({
        type: invoiceControlTypes.GETTING_EXCEL_INVOICES
    })
    httpClient.post(endPoint, GetInvoiceStatusRequest)
        .then((response) => {
            const ExcelInvoices = response.data.result
            dispatch({
                type: invoiceControlTypes.GET_EXCEL_INVOICES,
                ExcelInvoices: ExcelInvoices
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: invoiceControlTypes.EXCEL_INVOICE_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
        })
        .finally(() => {
            if (exportFunction && typeof exportFunction === "function")
                exportFunction()
        })
}
export const setError = (ErrorCode, ErrorDescription) => dispatch => {
    dispatch({
        type: invoiceControlTypes.INVOICE_ERROR,
        ErrorCode: ErrorCode,
        ErrorDescription: ErrorDescription
    })
}
export const clearInvoiceError = () => dispatch => {
    dispatch({
        type: invoiceControlTypes.INVOICE_ERROR,
        ErrorCode: -1,
        ErrorDescription: null
    })
}

