import React from 'react'
import { Lock, Delete, Edit, LockOpen, YesTick, Close, LoadingSpinner, Document } from '@dfds-ui/icons'
import moment from 'moment'

export const IncomingInvoiceTableSchema = (schemaLanguage) => {
    return {
        // rowId: "number", //unique ID
        RowContextMenu: [
            {
                Text: schemaLanguage.EditInvoice,
                Icon: <Edit />,
                Name: "editInvoice",
                // ActiveCheck: [{ columnId: "isLocked", Equal: false }]
            },
            {
                Text: schemaLanguage.DeleteInvoice,
                Icon: <Delete />,
                Name: "deleteInvoice",
                // ActiveCheck: [{ columnId: "isLocked", Equal: false }]
            },
            {
                Text: schemaLanguage.Integrate,
                Icon: <Edit />,
                Name: "integrate",
                // ActiveCheck: [{ columnId: "isLocked", Equal: false }]
            }
        ],
        ColumnDesign: [

            {
                columnId: "isIntegrated",
                Text: schemaLanguage.Integrated,
            },
            {
                columnId: "isEInvoice",
                Text: schemaLanguage.EInvoice,
            },
            {
                columnId: "invoiceDate",
                Text: schemaLanguage.InvoiceDate,

            },
            {
                columnId: "invoiceId",
                Text: schemaLanguage.InvoiceId,

            },
            {
                columnId: "customerNumber",
                Text: schemaLanguage.CustomerNumber,
            },
            {
                columnId: "customerName",
                Text: schemaLanguage.CustomerName,
            },
            {
                columnId: "invoiceTotalAmount",
                Text: schemaLanguage.InvoiceAmount,
            },
            {
                columnId: "invoiceTotalAmountAsTry",
                Text: schemaLanguage.InvoiceAmountTL,
            },
            {
                columnId: "invoiceCurrency",
                Text: schemaLanguage.InvoiceCurrency,
            },
            {
                columnId: "exchangeRate",
                Text: schemaLanguage.ExchangeRate,
            },
        ],
        DataDesign: [
            {
                columnId: "isIntegrated",
                dataType: "icon",
                dataCheck: (data) => isIntegratedIcon(data)
            },
            {
                columnId: "isEInvoice",
                dataType: "icon",
                dataCheck: (data) => isIntegratedIcon(data)
            },
            {
                columnId: "invoiceDate",
                dataType: "EPOCHDATE-SHORT",

            },
            {
                columnId: "invoiceId",

            },
            {
                columnId: "customerNumber",
            },
            {
                columnId: "customerName",
            },
            {
                columnId: "invoiceTotalAmount",
                dataType: "number",
                numberDigit: 2,

            },
            {
                columnId: "invoiceTotalAmountAsTry",
                dataType: "number",
                numberDigit: 2,

            },
            {
                columnId: "invoiceCurrency",
            },
            {
                columnId: "exchangeRate",
                dataType: "number",
                numberDigit: 6,
            },
            // {
            //     columnId: "differenceAmountAsTry",
            //     dataType: "dontshow",
            // },
        ]
    }
}
const isIntegratedIcon = (data) => {
    if (!data) {
        return <Close style={{ color: "#be1e2d" }} />
    } else {
        return <YesTick style={{ color: "#4caf50" }} />
    }
}
function randomDate(start, end) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}
export const DummyCreator = () => {
    let DummyArray = []
    for (let index = 0; index < 200; index++) {
        let myObject = {}
        myObject.isIntegrated = Math.floor(Math.random() * 10) > 5
        myObject.isEInvoice = Math.floor(Math.random() * 10) > 5
        myObject.invoiceDate = moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000))).unix()
        // .format('MM/DD/YYYY');
        myObject.invoiceId = makeid(8)
        myObject.customerNumber = makeid(8)
        myObject.customerName = makeid(8)
        myObject.invoiceAmount = makeid(8)
        myObject.invoiceAmountAsTry = makeid(8)
        myObject.invoiceCurrency = makeid(8)
        myObject.exchangeRate = makeid(8)
        DummyArray.push(myObject)
    }
    return DummyArray
}
function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
