import React from 'react'
export const InvoiceControlTableSchema = (schemaLanguage) => {
    return {
        rowId: "sourceInvoiceId", //unique ID
        ColumnDesign: [
            {
                columnId: "sourceInvoiceId",
                Text: schemaLanguage.sourceInvoiceId,

            },
            {
                columnId: "legalId",
                Text: schemaLanguage.legalId,
            }
        ],
        DataDesign: [
            {
                columnId: "sourceInvoiceId",
            },
            {
                columnId: "legalId",
            }
        ]
    }
}


const DummyCreator = () => {
    let DummyArray = []
    for (let index = 0; index < 100; index++) {
        let myObject = {}
        myObject.phxId = 1579507610
        myObject.legalId = 1111111111
        myObject.type = "DENEME DENEME"
        myObject.status = "5"
        DummyArray.push(myObject)
    }
    return DummyArray
}
export const Dummy = DummyCreator()