const DFDSStyleColumn = {
    columnStyle: {
        border: {
            left: { style: 'thin', color: { argb: 'FFFFFFFF' } },
            right: { style: 'thin', color: { argb: 'FFFFFFFF' } }

        },
        font: {
            color: { argb: "FFFFFFFF" }
        },
        fill: {
            type: 'pattern',
            pattern: 'darkTrellis',
            // fgColor: { argb: 'FFFFFFFF' },
            bgColor: { argb: 'FF002B45' }
        }
    },
}
export const InvoiceControlExcelSchema =
{
    Sheets: [
        {
            name: "Invoice Check List",
            id: "invoicesSheet",
            columns: [
                { header: 'D365FO Id', key: 'sourceInvoiceId', width: 12 },
                { header: 'Legal Id', key: 'legalId', width: 20 },
            ],
            columnStyle: DFDSStyleColumn.columnStyle,
        },
    ]
}